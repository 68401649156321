import * as React from 'react';
import {
    Row
} from 'antd';

export const Footer = () => {
    return (
        <Row justify="center" style={{
            marginTop:60,
            padding:36,
        }}>
            <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} />
            Code Factory All Rights Reserved
        </Row>
    )
}
