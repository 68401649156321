import * as React from 'react';
import {TopLogo} from "../logo/TopLogo";
import {Footer} from "../footer/Footer";
import {
    Helmet
} from 'react-helmet';
import {useStaticQuery, graphql} from "gatsby";

import 'src/styles/global.less';

interface ISeoProps {
    title: string;
    description: string;
    pathname: string;
    image: string;
    meta?: any[];
}

interface IDefaultLayoutProps {
    seo: ISeoProps;
}

export const DefaultLayout: React.FC<IDefaultLayoutProps> = (props) => {
    const {site} = useStaticQuery(
        graphql`
            query {
                site{
                    siteMetadata{
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }`
    )

    const {seo} = props;

    const metaDescription = seo.description || site.siteMetadata.description;

    return (
        <main className="root">
            <Helmet title={seo.title}
                    titleTemplate={`%s | ${site.siteMetadata.title}`}
                    meta={[
                        {
                            name: 'description',
                            content: metaDescription,
                        },
                        {
                            property: 'og:title',
                            content: seo.title,
                        },
                        {
                            property: 'og:description',
                            content: metaDescription,
                        },
                        {
                            property: 'og:url',
                            content: site.siteMetadata.siteUrl + seo.pathname,
                        },
                        {
                            property: 'og:image',
                            content: seo.image,
                        },
                        {
                            property: 'og:type',
                            content: 'website',
                        },
                        {
                            property: 'twitter:card',
                            content: 'summary',
                        },
                        {
                            property: 'twitter:creator',
                            content: site.siteMetadata.author,
                        },
                        {
                            name: 'twitter:title',
                            content: seo.title,
                        },
                        {
                            name: 'twitter:description',
                            content: metaDescription,
                        }
                    ].concat(seo.meta && [])}/>
            <section className="container">
                <TopLogo/>
            </section>
            {props.children}
            <Footer/>
        </main>
    )
}
