import * as React from 'react';
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {Link} from 'gatsby';

export const TopLogo = () => {
    const logo = useStaticQuery(
        graphql`
            query LogoImgQuery{
                file(relativePath: {eq: "box-logo.jpg"}, sourceInstanceName: {eq: "images"}) {
                    childImageSharp {
                        fixed(height: 30, width:30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `
    );

    return (
        <Link to="/">
            <Img fixed={logo.file.childImageSharp.fixed} alt="logo" className="top-logo"/>
        </Link>
    )
};
